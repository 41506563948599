.projects {
  width: 100%;
  margin-left: 300px;

  .projectsContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 64px 64px 16px;

    ul {
      li {
        margin-left: 20px;
        margin-bottom: 0px;
        font-size: 24px;
      }
    }

    h3 {
      a {
        text-decoration: underline;
      }
    }

    .projectsTitle {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 64px;
      }

      h3 {
        margin-top: -10px;
      }
    }
  }
}
