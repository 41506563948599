.OS {
  height: 100vh;
  width: 100%;
  background-color: rgb(62, 150, 151);
  overflow: hidden;
  position: relative;

  .helper {
    position: absolute;
    bottom: 32px;
    right: 0;

    .helperText {
      background-color: #fbffc4;
      width: 260px;
      position: absolute;
      bottom: 170px;
      right: 40px;
      border-radius: 8px;
      border: 2px solid #000;
      padding: 8px;

      &::before {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 80%;
        transform: translateX(-75%);
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid #000;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 80%;
        transform: translateX(-80%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fbffc4;
      }
    }
  }
}
