.clock {
  border: 1px solid;
  border-color: rgb(134, 137, 141) rgb(255, 255, 255) rgb(255, 255, 255)
    rgb(134, 137, 141);
  padding: 0 4px;
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    color: #000;
    font-size: 14px;
  }
}
