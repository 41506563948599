.tabs {
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  gap: 4px;

  .tabContainer {
    display: flex;
    flex: 1 1 0%;
    max-width: 300px;
    width: 100%;
    cursor: pointer;
    border: 1px solid;
    border-color: rgb(255, 255, 255) rgb(0, 0, 0) rgb(0, 0, 0)
      rgb(255, 255, 255);

    &.active {
      border-color: rgb(0, 0, 0) rgb(255, 255, 255) rgb(255, 255, 255)
        rgb(0, 0, 0);

      .tab {
        background-image: linear-gradient(45deg, white 25%, transparent 25%),
          linear-gradient(-45deg, white 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, white 75%),
          linear-gradient(-45deg, transparent 75%, white 75%);
        background-size: 4px 4px;
        background-position: 0px 0px, 0px 2px, 2px -2px, -2px 0px;
      }
    }

    .tab {
      width: 100%;
      padding: 0 4px;
      display: flex;
      align-items: center;
      gap: 4px;
      align-items: center;
      font-size: 10vw;
      font-weight: 600;
    }
  }
}
