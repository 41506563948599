.resume {
  background-color: white;
  padding: 12px;
  box-sizing: border-box;
  border-width: 2px 0px;
  border-style: solid;
  border-color: black;
  border-image: initial;
  width: 100%;
  align-items: center;
  gap: 24px;
  img {
    width: 56px;
    height: 56px;
  }

  .resumeLink {
    display: flex;
    flex-direction: column;

    a {
      text-decoration: underline;
      font-family: MillenniumBold;
    }
  }
}
