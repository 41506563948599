.experience {
  width: 100%;
  margin-left: 300px;

  .experienceContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 64px 64px 16px;

    .experienceTitle {
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        h1 {
          font-size: 64px;
        }

        a {
          font-family: Millennium, sans-serif;
          text-decoration: underline;
          margin-bottom: 13px;
          color: blue;
        }
      }

      .subtitle {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
  }
}
