.notFound {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .notFoundError {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    p {
      max-width: 480px;
    }
  }
}
