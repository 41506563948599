.windowContainer {
  background-color: rgb(195, 198, 202);
  position: absolute;
  z-index: 999;

  .window {
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(195, 198, 202) rgb(0, 0, 0) rgb(0, 0, 0)
      rgb(195, 198, 202);

    .windowContent {
      border-width: 1px;
      border-style: solid;
      border-color: rgb(255, 255, 255) rgb(134, 137, 141) rgb(134, 137, 141)
        rgb(255, 255, 255);
      border-image: initial;
      flex: 1 1 0%;
      padding: 2px;
      flex-direction: column;

      nav {
        background-color: rgb(0, 0, 163);
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 2px;
        box-sizing: border-box;
        color: white;

        .navbarTitle {
          cursor: all-scroll;
          display: flex;
          align-items: center;
          gap: 8px;
          height: 100%;
          width: 100%;

          figure {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            img {
              position: absolute;
              top: 1px;
              left: 3px;
            }
          }

          p {
            font-family: lores-15-bold-alt-oakland;
            font-size: 16px;
          }
        }
        .windowActions {
          display: flex;
          align-items: center;
          gap: 2px;

          .windowActionContent {
            background: rgb(195, 198, 202);
            cursor: pointer;
            width: 20px;
            height: 20px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(255, 255, 255) rgb(0, 0, 0) rgb(0, 0, 0)
              rgb(255, 255, 255);
            border-image: initial;
            background: rgb(195, 198, 202);
            cursor: pointer;

            button {
              img {
                width: 16px !important;
                height: 16px !important;
              }
            }
          }
        }
      }

      footer {
        flex-shrink: 1;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;

        .footerCopyright {
          height: 100%;
          border-width: 1px;
          border-style: solid;
          border-color: rgb(134, 137, 141) rgb(255, 255, 255) rgb(255, 255, 255)
            rgb(134, 137, 141);
          border-image: initial;
          padding: 2px;
          flex: 0.714286 1 0%;
          display: flex;
          align-items: center;
          padding: 0 8px;

          p {
            font-size: 14px;
            line-height: 14px;
          }
        }

        .footerBox {
          border-width: 1px;
          border-style: solid;
          border-color: rgb(134, 137, 141) rgb(255, 255, 255) rgb(255, 255, 255)
            rgb(134, 137, 141);
          padding: 2px;
          width: 30px;
          height: 100%;
          margin-left: 2px;
        }

        .footerBoxExtend {
          border-width: 1px;
          border-style: solid;
          border-color: rgb(134, 137, 141) rgb(255, 255, 255) rgb(255, 255, 255)
            rgb(134, 137, 141);
          border-image: initial;
          padding: 0px;
          flex: 0.285714 1 0%;
          justify-content: flex-end;
          align-items: flex-end;
          margin-left: 2px;
          height: 100%;
          position: relative;
        }
        .resize {
          cursor: nw-resize;
          position: absolute;
          right: 0px;
          bottom: 0px;
          padding: 5px;
        }
      }

      .windowScreen {
        border-width: 1px;
        border-style: solid;
        border-color: rgb(134, 137, 141) rgb(255, 255, 255) rgb(255, 255, 255)
          rgb(134, 137, 141);
        border-image: initial;
        flex-grow: 1;
        margin-top: 8px;
        margin-bottom: 8px;
        overflow: hidden;
        height: 100%;

        .windowScreenContainer {
          background-color: rgb(255, 255, 255);
          border-width: 1px;
          border-style: solid;
          border-color: rgb(0, 0, 0) rgb(195, 198, 202) rgb(195, 198, 202)
            rgb(0, 0, 0);
          border-image: initial;
          flex: 1 1 0%;
          overflow-y: scroll;
          height: 100%;
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }
}

.overlay {
  .overlayContainer {
    width: 100%;
    height: 100%;
    position: relative;

    .top,
    .bottom,
    .left,
    .right {
      background-color: rgb(255, 255, 255);
      pointer-events: none;
      background-image: linear-gradient(45deg, black 25%, transparent 25%),
        linear-gradient(-45deg, black 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, black 75%),
        linear-gradient(-45deg, transparent 75%, black 75%);
      background-size: 4px 4px;
      background-position: 0px 0px, 0px 2px, 2px -2px, -2px 0px;
    }

    .top {
      width: 100%;
      height: 8px;
    }

    .bottom {
      width: 100%;
      height: 8px;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .left,
    .right {
      width: 8px;
      height: 100%;
    }

    .right {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
