.musicPlayerContainer {
  overflow: hidden;
  background-color: rgb(195, 198, 202);
  .albumCover {
    display: flex;
    flex-direction: column;

    video {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }

    h3,
    p {
      text-align: center;
      padding: 0 16px;
    }

    .musicPlayerProgressbar {
      height: 30px;
      width: 100%;
      padding: 0 16px;

      .musicPlayerBar {
        width: 100%;
        box-shadow: inset -1px -1px #2b2b2b, inset 1px 1px #fff,
          inset -2px -2px grey, inset 2px 2px #747474;
        box-shadow: var(--border-raised-outer), var(--border-raised-inner);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .progress {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 4px;
          margin: auto 0;
          width: 20px;
          height: 23px;
          background-color: var(--surface-hover);
        }

        .progressTimer {
          position: relative;
          z-index: 99;
          font-family: Millennium, "Times New Roman", Times, serif;
        }
      }
    }

    .musicPlayerButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      gap: 8px;

      button {
        min-width: 175px;
        height: 32px;
        font-family: MillenniumBold, "Times New Roman", Times, serif;
        border: none;
        border-radius: 0;
        box-shadow: inset -1px -1px #2b2b2b, inset 1px 1px #fff,
          inset -2px -2px grey, inset 2px 2px #747474;
        box-shadow: var(--border-raised-outer), var(--border-raised-inner);
        box-sizing: border-box;
      }
    }
  }
}
