.navbar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32px;
  background: rgb(195, 198, 202);
  border-top: 1px solid rgb(195, 198, 202);
  z-index: 9999;

  .tabContainer {
    border-top: 1px solid rgb(255, 255, 255);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 4px;
    gap: 4px;

    p {
      color: #000;
      font-size: 16px;
    }

    .start {
      box-sizing: border-box;
      cursor: pointer;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(255, 255, 255) rgb(0, 0, 0) rgb(0, 0, 0)
        rgb(255, 255, 255);
      border-image: initial;
      display: flex;
      align-items: center;
      padding: 0 4px;
      gap: 4px;
    }
  }
}
